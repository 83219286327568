import React, { useState } from 'react';
import { Button, Modal, Form, Input, DatePicker, Radio, Divider } from 'antd';
import axios from "../API/axios";
import { UserOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import "./Calendar.css"

// const moment = require('moment-timezone');

const { RangePicker } = DatePicker;
const AddEventModal = () => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
        if (localStorage.getItem('loginEmail') && localStorage.getItem('isLoggedIn')) {
            setOpen(true);
        }
        else {
            // console.log("need to login to access the add event feature");
            showLoginModal();
        }
    };

    const handleCancel = (e) => {
        // console.log(e);
        setOpen(false);
    };

    const [form] = Form.useForm();
    const [formlogin] = Form.useForm();
    const [newEvent, setNewEvent] = useState({ email: "", creator: "", user: "", event: "", title: "", visibility: "", type: "", contact_email: "", rangeTimePicker: [] })
    const [isLoading, setLoading] = useState(false);
    const [btnDisabled, setDisabled] = useState(false);

    // // to reload the page showing new added events on the calendar
    const onFinish = (fieldsValue) => {
        
        if (localStorage.getItem('loginEmail') && localStorage.getItem('isLoggedIn')) {
            const rangeTimeValue = fieldsValue['range-time-picker'];
            // console.log(rangeTimeValue[0].$d);
            // const startTime = moment(newEvent.rangeTimePicker[0]).tz('Australia/Sydney').format();
            // const endTime = moment(newEvent.rangeTimePicker[1]).tz('Australia/Sydney').format();

            // console.log('Start Time in Australia/Sydney Time Zone:', startTime);
            // console.log('End Time in Australia/Sydney Time Zone:', endTime);

            newEvent.rangeTimePicker.push(rangeTimeValue[0].$d);
            newEvent.rangeTimePicker.push(rangeTimeValue[1].$d);
            setLoading(true);
            setDisabled(true);

            axios.post("/meetup/create",
                {
                    user: localStorage.getItem("user_id"),
                    event: newEvent.event,
                    title: newEvent.title,
                    startTime: newEvent.rangeTimePicker[0],
                    endTime: newEvent.rangeTimePicker[1],
                    type: newEvent.type,
                    visibility: newEvent.visibility,
                    creator_name: newEvent.creator,
                    contact_email: newEvent.email,
                }).then((response) => {
                    if (response.data.message == "Created a new meetup") {
                    
                        // send email to user
                        axios.post("/user/event-email",
                        {
                            email: localStorage.getItem("loginEmail"),
                            eventTitle: newEvent.title,
                            eventType: newEvent.type,
                            startTime: newEvent.rangeTimePicker[0],
                            endTime: newEvent.rangeTimePicker[1],
                            creator: newEvent.creator,
                            eventDetail: newEvent.event,
                            visibility: newEvent.visibility,
                            contact_email: newEvent.email,
                        }).then((response) => {
                            if (response.data.success){
                                setLoading(false);
                                setDisabled(false);
                                alert("Added event successfully!");
                                window.location.reload(false);
                            }
                        })
                    }
                    else {
                        setLoading(false);
                        setDisabled(false);
                        if (response.data.message == "The event clashes with existing activities"){
                            // console.log("clash");
                            alert("The event clashes with existing activities, please select another time!")
                        }
                        else{
                            // console.log(response);
                            alert("Error! Please create again!");
                        }
                    }
                })
        }
        else {
            // console.log("need to login to access the add event feature");
            showLoginModal();
        }

    };

    const handleSubmit = () => {
        form.submit();  // This triggers the form's onFinish
    };


    // form information 
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        },
    };

    // date time picker config info below
    const rangeConfig = {
        rules: [
            {
                type: 'array',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    // handle the log in modal
    const [loginmodal, setloginmodal] = useState(false);
    const [newLogin, setNewLogin] = useState({ loginEmail: "", password: "" });

    const handleLoginCancel = () => {
        setloginmodal(false);
    }

    const showLoginModal = () => {
        setloginmodal(true);
    }

    const handleSubmitLogin = () => {
        formlogin.submit();  // This triggers the form's onFinish
    };

    const onLogin = () => {
        axios
            .post("/user/login", {
                loginEmail: newLogin.loginEmail,
                password: newLogin.password,
            })
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    // console.log(response.data.user);
                    // console.log("logined in");
                    // this.setState({
                    //     isLoggedIn: true,
                    //     isLoginModalVisible: false
                    // });
                    localStorage.setItem('isLoggedIn', 'true'); // Set login state in local storage
                    localStorage.setItem('loginEmail', newLogin.loginEmail); // Store login email
                    localStorage.setItem('user_id', response.data.user.id);
                    localStorage.setItem('user_firstname', response.data.user.givenName);
                    setloginmodal(false);
                    window.location.reload(false);

                    // this.props.props.history.push("/profile", {
                    //     loginEmail: this.state.loginEmail,
                    //     key: "9",
                    // });
                    // window.location.href = '/';
                    // open the add events modal to let the user add events now, since already logged in 
                    setOpen(true);

                } else {
                    // console.log("failed");
                    // this.setState({
                    //     isLoggedIn: false,
                    //     isLoginModalVisible: false
                    // });
                    localStorage.removeItem('isLoggedIn'); // Remove login state from local storage
                    localStorage.removeItem('loginEmail');
                    alert("You are kindly requested to provide the appropriate login credentials, including your email address and password, to proceed with the authentication process.");
                }
            })
            .catch((error) => {
                // console.log(error);
            });

    };

    //  new user -> register 
    const handleRegisterClick = () => {
        // console.log("switch to register page");
        window.location.href = "/register";
    }

    // existing user but forget password -> reset password
    const handleForgotPasswordClick = () => {
        // console.log("switch to forgot password page");
    }


    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center', flexGrow: 1 }}>
                        <Divider orientation='center'><h3>Meetups Activities Calendar</h3></Divider>
                    </div>
                    <Button
                        onClick={showModal}
                        className="smallPrettyButton"
                        style={{ flexGrow: 0 }}
                    >
                        <PlusOutlined />Add Meetup Event!
                    </Button>
                </div>

                <br/>

                <Modal
                    title="Add New Event"
                    open={open}
                    onOk={handleSubmit}
                    onCancel={handleCancel}
                    footer={[
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button key="ok" type="primary" onClick={handleSubmit} style={{ width: '48%' }}
                                loading={isLoading}
                                disabled={btnDisabled}>
                                Create
                            </Button>
                            <Button key="cancel" onClick={handleCancel} style={{ width: '48%' }}>
                                Cancel
                            </Button>
                        </div>
                    ]}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Form
                        form={form}
                        {...layout}
                        name="nest-messages"
                        onFinish={onFinish}
                        // style={{
                        //     maxWidth: 600,
                        // }}
                        style={{ backgroundColor: "white", width: '100%', padding: '20px', borderRadius: '8px', maxWidth: 600 }}
                        validateMessages={validateMessages}
                    >
                        <Form.Item
                            name={['user', 'name']}
                            label="Event Title"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="range-time-picker" label="Date and Time" {...rangeConfig}
                        >
                            <RangePicker showTime format="YYYY-MM-DD HH:mm" />
                        </Form.Item>

                        <Form.Item
                            name={['user', 'creator']}
                            label="Your Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            onChange={(e) => setNewEvent({ ...newEvent, creator: e.target.value, })}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'email']}
                            label="Contact Email"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                },
                            ]}
                            onChange={(e) => setNewEvent({ ...newEvent, email: e.target.value, })}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item name={['user', 'Event introduction']} label="Event Details"
                            onChange={(e) => setNewEvent({ ...newEvent, event: e.target.value, })}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item name="radio-group1" label="Event Type"
                            onChange={(e) => setNewEvent({ ...newEvent, type: e.target.value, })}>
                            <Radio.Group>
                                <Radio value="Online Meeting">Online Meeting</Radio>
                                <Radio value="In-person Meeting">In-person Meeting</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="radio-group2" tooltip="Select 'Public' if you want to make this event public, allowing anyone who visits this website to view it."
                            label="Visibility Status" onChange={(e) => setNewEvent({ ...newEvent, visibility: e.target.value, })}>
                            <Radio.Group>
                                <Radio value="public">Public</Radio>
                                <Radio value="private">Private</Radio>
                            </Radio.Group>
                        </Form.Item>

                    </Form>
                    </div>
                </Modal>

                {/*login modal here*/}
                <Modal
                    title="Login to Schedule Meetups"
                    open={loginmodal}
                    onOk={handleSubmitLogin}
                    onCancel={handleLoginCancel}
                    footer={[
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button key="ok" type="primary" onClick={handleSubmitLogin} style={{ width: '48%' }}>
                                Login
                            </Button>
                            <Button key="cancel" onClick={handleLoginCancel} style={{ width: '48%' }}>
                                Cancel
                            </Button>
                        </div>
                    ]}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Form
                        form={formlogin}
                        name="normal_login"
                        initialValues={{ remember: true }}
                        onFinish={onLogin}
                        style={{ backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '8px' }}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                            onChange={(e) => setNewLogin({ ...newLogin, loginEmail: e.target.value })}

                        >
                            <Input prefix={<UserOutlined />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                            onChange={(e) => setNewLogin({ ...newLogin, password: e.target.value })}
                        >
                            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            {/* Registration Button */}
                            <Button
                                type="link"
                                onClick={handleRegisterClick}
                                style={{ float: 'right' }}
                            >
                                Register
                            </Button>
                            {/* Forgot Password Button */}
                            <Button
                                type="link"
                                onClick={handleForgotPasswordClick}
                                style={{ float: 'right' }}
                            >
                                Forgot password?
                            </Button>
                        </Form.Item>
                    </Form>
                    </div>


                </Modal>
            </div>
        </>
    );
};
export default AddEventModal;