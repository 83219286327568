import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Button, Typography, Col, Row } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import axios from '../API/axios';

const { Link } = Typography;

const ChangePasswordModal = ({ visible, onClose }) => {
    const [form] = Form.useForm();
    const [newLogin, setNewLogin] = useState({
        loginEmail: "", oldPassword: "", newPassword: "", confirmPassword: ""
    });
    const [passwordStrength, setStrength] = useState("");

    // Function to check password strength
    const checkPasswordStrength = (value) => {
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

        const hasUppercase = uppercaseRegex.test(value);
        const hasSpecialChar = specialCharRegex.test(value);

        if (hasUppercase && hasSpecialChar && value.length >= 8) {
            setStrength("strong");
        } else if (hasUppercase || hasSpecialChar || value.length >= 8) {
            setStrength("medium");
        } else {
            setStrength("weak");
        }
    };

    const getPasswordStrengthColor = () => {
        switch (passwordStrength) {
            case 'weak':
                return 'red';
            case 'medium':
                return 'orange';
            case 'strong':
                return 'green';
            default:
                return 'inherit';
        }
    };

    // useEffect to update password strength when password changes
    useEffect(() => {
        checkPasswordStrength(newLogin.newPassword);
    }, [newLogin.newPassword]);

    const handleChangePassword = () => {
        if (localStorage.getItem("loginEmail") === newLogin.loginEmail) {
            if (newLogin.newPassword === newLogin.confirmPassword && newLogin.newPassword.length >= 8) {
                axios.post("/user/change/password", { loginEmail: newLogin.loginEmail, oldPassword: newLogin.oldPassword, newPassword: newLogin.newPassword })
                    .then((response) => {
                        if (response.data.success) {
                            alert(response.data.message);
                            localStorage.removeItem('isLoggedIn');
                            localStorage.removeItem('loginEmail');
                            localStorage.removeItem('user_id');
                            localStorage.removeItem('user_firstname');
                            window.location.href = '/';
                        }
                        else {
                            alert(response.data.message);
                        }
                    })
            }
            else {
                if (newLogin.newPassword.length >= 8) {
                    alert("The second entry for password confirmation does not match the initial input. Kindly verify and re-enter your password to ensure accuracy and consistency.")
                }
                else {
                    alert("Password must be at least 8 characters.");
                }
            }

        }
        else {
            alert("Kindly endeavor to initiate a password modification request solely for the email account that is presently authenticated and active in the current session.")
        }

    }

    return (
        <Modal
            title="Change Password"
            visible={visible}
            onCancel={onClose}
            footer={[
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button key="ok" type="primary" onClick={handleChangePassword} style={{ width: '48%' }}>
                        Change Password
                    </Button>
                    <Button key="cancel" onClick={onClose} style={{ width: '48%' }}>
                        Cancel
                    </Button>
                </div>
            ]}
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Form form={form} onFinish={handleChangePassword}
                    style={{ backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '8px' }}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your login email!' }]}
                        onChange={(e) => setNewLogin({ ...newLogin, loginEmail: e.target.value })}
                    >
                        <Input prefix={<UserOutlined />} type="email" placeholder="Login Email" />
                    </Form.Item>
                    <Form.Item
                        name="currentPassword"
                        rules={[{ required: true, message: 'Please input your current password!' }]}
                        onChange={(e) => setNewLogin({ ...newLogin, oldPassword: e.target.value })}
                    >
                        <Input prefix={<LockOutlined />} type="password" placeholder="Current Password" />

                    </Form.Item>
                    <Row justify="end" style={{ marginBottom: '20px' }}>
                        <Col>
                            <Link href="/password" style={{ zIndex: 1000 }}>Forgot Password?</Link>
                        </Col>
                    </Row>

                    <Form.Item
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Please input your new password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.length < 8) {
                                        return Promise.reject(
                                            new Error('Password must be at least 8 characters.')
                                        );
                                    }
                                    if (getFieldValue('newPasswordConfirm') && value !== getFieldValue('newPasswordConfirm')) {
                                        return Promise.reject(
                                            new Error('Passwords do not match.')
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => {
                            setNewLogin({ ...newLogin, newPassword: e.target.value })
                            checkPasswordStrength(e.target.value);
                        }}


                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="New Password"
                        />
                    </Form.Item>

                    <Form.Item
                        name="newPasswordConfirm"
                        rules={[
                            { required: true, message: 'Please confirm your new password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (getFieldValue('newPassword') && value !== getFieldValue('newPassword')) {
                                        return Promise.reject(
                                            new Error('Passwords do not match.')
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => setNewLogin({ ...newLogin, confirmPassword: e.target.value })}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Confirm New Password"
                        />
                    </Form.Item>

                    {newLogin.newPassword && (
                        <div style={{ marginTop: '16px', textAlign: 'center' }}>
                            <strong>Password Strength:</strong>
                            <span
                                style={{
                                    marginLeft: '8px',
                                    color: getPasswordStrengthColor(),
                                }}
                            >
                                {passwordStrength}
                            </span>
                        </div>
                    )}







                    {/* <Form.Item
                    name="newPassword"
                    rules={[{ required: true, message: 'Please input your new password!' }]}
                    onChange={(e) => setNewLogin({ ...newLogin, newPassword: e.target.value })}
                >
                        <Input prefix={<LockOutlined />} type="password" placeholder="New Password" />
                </Form.Item>
                <Form.Item
                    name="newPassword confirm"
                    rules={[{ required: true, message: 'Please confirm your new password!' }]}
                    onChange={(e) => setNewLogin({ ...newLogin, confirmPassword: e.target.value })}
                >
                        <Input prefix={<LockOutlined />} type="password" placeholder="Confirm New Password" />
                </Form.Item> */}

                </Form>
            </div>
        </Modal>
    );
};

export default ChangePasswordModal;
