// function to handle with the connection between the front-end and the back-end
import _axios from "axios";

const axios = baseUrl => {
    const instance = _axios.create({
        // connected to the heroku
        baseURL: "https://yitongp.onrender.com" || "http://localhost:8000"   // use localhost when test in local, use live server when push to github and deploy the web 
        // baseURL: "http://localhost:8000" || "https://yitongp.onrender.com"
    });
    return instance;
};

export { axios };

export default axios();