import React, { useState, useEffect } from 'react';
import { Timeline, Divider } from 'antd';
import axios from "../API/axios";

const Event_timeline = (props) => {
    const [publicEvents, setPublic] = useState([]);


    // get all public events 
    useEffect(() => {
        axios
            .get(
                "/meetup/show/public/" +
                "?visibility=public"
            )
            .then((response) => {
                if (response.data.success) {
                    setPublic(response.data.events);
                }
            });
    }, []);

    // Keeping the event if it ends in the future
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    var futureEvents = []
    // var eventLength = publicEvents.length < 10 ? publicEvents.length : 10;
    for (let i = 0; i < publicEvents.length; i++) {
        publicEvents[i].start = new Date(publicEvents[i].start);
        publicEvents[i].label = publicEvents[i].start.toLocaleString().split(',')[0];
        publicEvents[i].children = publicEvents[i].title;
        if (publicEvents[i].start >= currentDate){
            futureEvents.push(publicEvents[i]);
        }
    }
    futureEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
    // sort all public events, and extract events that will happening from current date 

    return (
        <>
            <Divider orientation="center"><h3>Upcoming Events</h3></Divider>
            <Timeline
                mode={'left'}
                items={futureEvents}
            />
        </>
    );
};
export default Event_timeline;