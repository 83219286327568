import { PlusOutlined } from '@ant-design/icons';
import "./Inquiry.css";
import React, { useState } from 'react';
import {
  Layout,
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Typography
} from 'antd';
import Navigation from '../Components/Navigation'
import Footer from "../Components/Footer"
import axios from "../API/axios.js";

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;
// const normFile = (e) => {
//   if (Array.isArray(e)) {
//     return e;
//   }
//   return e?.fileList;
// };
const FormDisabledDemo = (props) => {
  const [newEvent, setNewEvent] = useState({ category: "", name: "", salutation: "", email: "", phoneNumber: "", inquiry: "", documents: "" })

  // to reload the page showing new added events on the calendar
  const handleSubmit = () => {
    // console.log(newEvent.category)
    // console.log(newEvent.name)
    // console.log(newEvent.salutation)
    // console.log(newEvent.email)
    // console.log(newEvent.phoneNumber)
    // console.log(newEvent.inquiry)
    if (newEvent.category && newEvent.name && newEvent.salutation && newEvent.email && newEvent.phoneNumber && newEvent.inquiry){
      // The datetime is changed into the correct Date form via 'new Date()'
      // console.log(newEvent.category)
      // console.log(newEvent.name)
      // console.log(newEvent.salutation)

      axios.post("./inquiry/create",
        {
          category: newEvent.category,
          name: newEvent.name,
          salutation: newEvent.salutation,
          email: newEvent.email,
          phoneNumber: newEvent.phoneNumber,
          inquiry: newEvent.inquiry,
          documents: newEvent.documents,  // documents path still need to figure out 
        }).then((response) => {
          // console.log(response)
          if (response.data.message == "created a new inquiry") {
            // console.log(response);
            alert("Added event successfully!");
            window.location.reload(false);
          }
          else {
            // console.log(response);
            alert("Error! Please create again!")
          }
        })
    }
    else{
      alert("Kindly ensure that all requisite information is duly provided.");
    }
    
  };

  return (
    <>
      <Layout>
        <Header>
          <Navigation props={props}></Navigation>
        </Header>

        <div className="form-header ">
          <Paragraph strong>Yes! Feedback and Questions are welcome. You can submit inquiries by submitting the form below and I will reply it ASAP!</Paragraph>
        </div>


        <Content className="form-container" >
          <Form
            labelCol={{
              span: 960,
            }}
            wrapperCol={{
              span: 960,
            }}
            layout="horizontal"
            disabled={false}
            style={{
              maxWidth: 960,
            }}
            onFinish={handleSubmit}
          >
            <Form.Item >
              <Title level={2}>Submit an Inquiry</Title>
            </Form.Item>
            <Form.Item name="Category" label="Category" onChange={(e) =>
              setNewEvent({
                ...newEvent,
                category: e.target.value,
              })
            } rules={[
              {
                required: true,
                message: "Please select your inquiry category."
              }
            ]}>
              <Radio.Group>
                <Radio value="questions"> Questions </Radio>
                <Radio value="feedback"> Feedback </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="Salutation" label="Salutation" onChange={(e) =>
              setNewEvent({
                ...newEvent,
                salutation: e.target.value,
              })
            } rules={[
              {
                required: true,
                message: "Please select the salutation that most accurately reflects your preferred form of address."
              },
            ]}>
              {/* <Select>
                <Select.Option value="mr.">Mr.</Select.Option>
                <Select.Option value="mrs.">Mrs.</Select.Option>
                <Select.Option value="ms.">Ms.</Select.Option>
                <Select.Option value="miss.">Miss.</Select.Option>

              </Select> */}
              <Radio.Group>
                <Radio value="Mr."> Mr. </Radio>
                <Radio value="Mrs."> Mrs. </Radio>
                <Radio value="Ms."> Ms. </Radio>
                <Radio value="Miss."> Miss. </Radio>
                <Radio value="Mx."> Mx. </Radio>
              </Radio.Group>

            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  name: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="E-mail"
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  email: e.target.value,
                })
              }
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Phone Number"
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  phoneNumber: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
              ]}
            >
              <Input />
            </Form.Item>


            <Form.Item name="Inquiry" label="Inquiry" onChange={(e) =>
              setNewEvent({
                ...newEvent,
                inquiry: e.target.value,
              })
            } rules={[
              {
                required: true,
                message: 'Please input your inquiry!',
              },
            ]}>
              <TextArea rows={10} />
            </Form.Item>
            {/* <Form.Item label="Documents" valuePropName="fileList" onChange={(e) =>
              setNewEvent({
                ...newEvent,
                documents: e.target.value,
              })
            }
              getValueFromEvent={normFile}>
              <Upload action="/upload.do" listType="picture-card">
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
            </Form.Item> */}
            <Form.Item label="">
              <Button onClick={handleSubmit} >Submit Inquiry</Button>
            </Form.Item>

          </Form>
        </Content>
        <br />
        <Footer></Footer>
      </Layout>
    </>
  );
};
export default () => <FormDisabledDemo />;