import React from "react";
import { BrowserRouter, Switch, Route, HashRouter } from "react-router-dom";
import App from "./App.js";
import OnlineResume from "./Online_resume/OnlineResume1.js";
import Blog from "./Blog/Blog.js";
import Inquiry from "./Inquiry/Inquiry.js";
import Meetup from "./Meetup/Meetup.js";
import Profile from "./CheckProfile/Profile.js";
import Register from "./Register/Register.js";
import Complete from "./Register/Complete_register.js";
import ForgotPassword from "./ForgotPassword/ForgotPassword.js";
class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={OnlineResume} />
                    {/* <Route path="/onlineresume" exact component={OnlineResume} /> */}
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/inquiry" exact component={Inquiry} />
                    <Route path="/meetup" exact component={Meetup} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/register" exact component={Register} />
                    <Route path="/complete" exact component={Complete} />
                    <Route path="/password" exact component={ForgotPassword} />

                </Switch>
            </BrowserRouter>
        );
    }
}

export default Router;
