import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import axios from "../API/axios";
import React, { useState, useEffect } from 'react';
import eventPop from "./Calendar_event_popup";
import AddEventButton from "./Calendar_event_modal";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

const locales = {
    "en-AU": require("date-fns/locale/en-AU"),
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});


const MyCalendar = () => {
    const [publicEvents, setPublic] = useState([]);
    const [privateEvents, setPrivate] = useState([]);
    useEffect(() => {
        axios
            .get(
                "/meetup/show/public/" +
                "?visibility=public"
            )
            .then((response) => {
                if (response.data.success) {
                    setPublic(response.data.events);
                }
            });
        axios
            .get(
                "/meetup/show/private/" +
                "?visibility=private"
            )
            .then((response) => {
                if (response.data.success) {
                    setPrivate(response.data.events);
                }
            });
    }, []);

    // combine the public and private events together
    var allEvents = []
    for (let i = 0; i < publicEvents.length; i++) {
        publicEvents[i].start = new Date(publicEvents[i].start);
        publicEvents[i].end = new Date(publicEvents[i].end);
        allEvents.push(publicEvents[i]);
    }
    for (let i = 0; i < privateEvents.length; i++) {
        privateEvents[i].start = new Date(privateEvents[i].start);
        privateEvents[i].end = new Date(privateEvents[i].end);
        allEvents.push(privateEvents[i]);
    }

    // console.log(allEvents);

    return (
        <>
            <AddEventButton></AddEventButton>
            <div className="calendars">
                {/* <AddEventButton></AddEventButton> */}
                <div>
                    {/* <h3>add events notes here...</h3> */}
                    <Calendar
                        events={allEvents}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectEvent={
                            (singleEvent) => {
                                <eventPop data={singleEvent} />
                            }
                        }
                        components={{
                            event:
                                eventPop,
                        }}
                        eventStyleGetter={(event, start, end, isSelected) => {
                            const style = {
                                backgroundColor: 'green', // Set the background color to green
                                color: 'white', // Set the text color to white
                                borderRadius: '5px', // Optional: Add rounded corners
                                border: '1px solid green', // Optional: Add a border
                            };

                            return {
                                style,
                            };
                        }}

                        style={{ height: 700 }}
                    />
                </div>
            </div>
        </>
    )
};

export default MyCalendar;