import React, { Component } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { LockOutlined, MailOutlined, VerticalLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import axios from "../API/axios";  // assuming axios instance is set up this way
import { withRouter } from 'react-router-dom';

const { Title } = Typography;

class ForgotPassword extends Component {
    constructor(props) {
        // console.log(props);
        super(props);
        this.state = {
            loginEmail: "",
            registered_email: "",
            password: "",
            password_confirm: "",

            // for generate code button 
            isLoading: false,
            countdown: 0,
            btnDisabled: false,

            // for verify code button 
            verify_code_status: false,
            verify_code: "",
            input_code: "",
            verify_btnDisabled: true,

            // check the strength of the password
            passwordStrength:"",
            passwordPass: false
        }
    }

    // Function to check password strength
    checkPasswordStrength = (value) => {
        // Define your password strength criteria
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

        // Check for uppercase letter
        const hasUppercase = uppercaseRegex.test(value);

        // Check for special character
        const hasSpecialChar = specialCharRegex.test(value);

        // Update password strength feedback
        if (hasUppercase && hasSpecialChar && value.length >= 8) {
            this.setState({ passwordStrength: "strong" });
        } else if (hasUppercase || hasSpecialChar || value.length >= 8) {
            this.setState({ passwordStrength: "medium" });
        } else {
            this.setState({ passwordStrength: "weak" });
        }
        if (value.length >= 8) {
            this.setState({ passwordPass: true });
        }
        else {
            this.setState({ passwordPass: false });
        }
    };

    getPasswordStrengthColor = () => {
        switch (this.state.passwordStrength) {
            case 'weak':
                return 'red';
            case 'medium':
                return 'orange';
            case 'strong':
                return 'green';
            default:
                return 'inherit';
        }
    };


    // for cancel button, just go back to the original back 
    handleCancel = () => {
        // console.log("cancel, goback");
        this.props.history.goBack();
    }

    // email verify by code function
    handleSendVerification = () => {
        if(this.state.loginEmail){
            this.setState({ isLoading: true, btnDisabled: true });
            axios.post("/user/send-password-code", { email: this.state.loginEmail })
                .then(response => {
                    if (response.data.success) {
                        // console.log(response.data);
                        this.setState({
                            verify_code: response.data.code,
                            registered_email: response.data.email,
                            isLoading: false,
                            countdown: 60
                        }, () => {
                            // console.log(this.state.verify_code);
                            // console.log(this.state.registered_email);
                        });
                        alert('Verification code sent to your email.');
                        this.setState({
                            verify_code: response.data.code,
                            registered_email: response.data.email,
                            isLoading: false,
                            countdown: 60
                        }, () => {
                            // console.log(this.state.verify_code);
                            // console.log(this.state.registered_email);
                        });

                        this.startCountdown();
                        return;
                    } else {
                        this.setState({ isLoading: false, btnDisabled: false });
                        // console.log(response.data);
                        alert(response.data.message);
                        this.setState({ loginEmail: "", registered_email: "" });
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false, btnDisabled: false });
                    // console.log(error);
                });
        }
        else{
            alert("Kindly provide your registered email address, so we may assist you in updating your password.");
        }
        
    }

    // countdown clock for genreate code button (after generating a code, there will be 60s frozen period for next generate code action)
    startCountdown = () => {
        const timer = setInterval(() => {
            this.setState(prevState => ({
                countdown: prevState.countdown - 1
            }));

            if (this.state.countdown <= 0) {
                clearInterval(timer);
                this.setState({ btnDisabled: false });
            }
        }, 1000);
    }

    handleConfirm = () => {
        // console.log(this.state.verify_code)
        // console.log(this.state.input_code)
        // console.log(this.state.loginEmail)
        // console.log(this.state.registered_email)
        if (this.state.password == this.state.password_confirm && this.state.password && this.state.verify_code_status && this.state.password.length >= 8){
            axios.post("/user/forgot/password", { loginEmail: this.state.loginEmail, newPassword: this.state.password })
                .then((response) => {
                    if (response.data.success) {
                        // console.log(response.data);
                        alert(response.data.message);
                        window.location.href = '/';
                    }
                    else {
                        // console.log(response.data);
                        alert(response.data.message);
                    }
                })
        }
        else{
            if (!this.state.password){
                // console.log("no password");
                alert("Please provide your new password!");
                return;
            }
            if (!this.state.verify_code_status){
                // console.log("not verified");
                alert("Please verify your email first");
                return;
            }
            if (!this.state.password.length >= 8) {
                // console.log("not verified");
                alert("Password must be at least 8 characters.");
                return;
            }
        }
        
    }



    handleVerify = () => {
        // console.log(this.state.verify_code)
        // console.log(this.state.input_code)
        // console.log(this.state.loginEmail)
        // console.log(this.state.registered_email)

        if (this.state.input_code == this.state.verify_code && this.state.input_code) {
            this.setState({
                verify_code_status: true,
                verify_btnDisabled: false
            });
        } else {
            if(this.state.input_code){
                alert('The verification code you have entered does not correspond with our records. We kindly request that you attempt the process once more with utmost accuracy.');
                this.setState({
                    verify_code_status: false
                });
            }
            else{
                alert("Kindly enter the verification code provided to you.");
                this.setState({
                    verify_code_status: false
                });
            }
            
        }
    }

    render() {
        return (
            <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
                <Title level={2}>Change Password</Title>
                <p>
                    If you have forgotten your password, please be assured that we are here to assist you in recovering it.
                </p>

                <Form
                    name="forgot_password"
                    onFinish={this.handleConfirm}
                    onCancel={this.handleCancel}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input your login email!' }]}
                        onChange={(e) => this.setState({ loginEmail: e.target.value })}
                    >
                        <Input prefix={<MailOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            onClick={this.handleSendVerification}
                            loading={this.state.isLoading}
                            disabled={this.state.btnDisabled}
                        >
                            {this.state.countdown > 0 ? `Resend Code after ${this.state.countdown} s` : "Generate Code"}
                        </Button>

                    </Form.Item>


                    <Form.Item
                        name="code"
                        rules={[{ required: true, message: 'Please input your verification code' }]}
                        onChange={(e) => this.setState({ input_code: e.target.value })}
                    >
                        <Input prefix={<VerticalLeftOutlined />} placeholder="Verification Code" />
                    </Form.Item>

                    <Form.Item>
                        {this.state.verify_code_status ? (
                            <Button disabled icon={<CheckCircleOutlined />} >
                                Verified Email
                            </Button>
                        ) : (
                            <Button onClick={this.handleVerify}>
                                Verify Email
                            </Button>
                        )}
                    </Form.Item>


                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your new password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    // Check password strength
                                    if (!value || value.length < 8) {
                                        return Promise.reject(
                                            new Error('Password must be at least 8 characters.')
                                        );
                                    }
                                    if (getFieldValue('password_confirm') && value !== getFieldValue('password_confirm')) {
                                        return Promise.reject(
                                            new Error('Passwords do not match.')
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => {
                            this.setState({ password: e.target.value });
                            this.checkPasswordStrength(e.target.value);
                        }}
                        hidden={this.state.verify_btnDisabled}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="New Password"
                        />
                    </Form.Item>


                    <Form.Item
                        name="password_confirm"
                        rules={[
                            { required: true, message: 'Please confirm your new password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    // Check password confirmation
                                    if (getFieldValue('password') && value !== getFieldValue('password')) {
                                        return Promise.reject(
                                            new Error('Passwords do not match.')
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => this.setState({ password_confirm: e.target.value })}
                        hidden={this.state.verify_btnDisabled}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Confirm New Password"
                        />
                    </Form.Item>

                    {/* Password strength feedback */}
                    {this.state.password && (
                        <div style={{ marginTop: '16px', textAlign: 'center' }}>
                            <strong>Password Strength:</strong>
                            <span
                                style={{
                                    marginLeft: '8px',
                                    color: this.getPasswordStrengthColor(),
                                }}
                            >
                                {this.state.passwordStrength}
                            </span>
                        </div>

                    )}


                    {/* <Form.Item
                        name="password"
                        rules={[{ required: true, type: 'password', message: 'Please input your new password' }]}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        hidden={this.state.verify_btnDisabled}
                    >
                        <Input  prefix={<LockOutlined />} type="password" placeholder="New Password" />
                    </Form.Item>

                    <Form.Item
                        name="password confirm"
                        rules={[{ required: true, type: 'password', message: 'Please confirm your new password' }]}
                        onChange={(e) => this.setState({ password_confirm: e.target.value })}
                        hidden={this.state.verify_btnDisabled}
                    >
                        <Input  prefix={<LockOutlined />} type="password" placeholder="Confirm New Password" />
                    </Form.Item> */}



                    <Form.Item
                        hidden={this.state.verify_btnDisabled}>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Confirm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="secondary" htmlType="Cancel" style={{ width: '100%' }} onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default withRouter(ForgotPassword);