// App.js
import React from 'react';
import { Layout, Menu, Modal, Form, Input, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
// import "./NavigationBar.css"
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from "../API/axios";
const { Header, Content } = Layout;

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        // console.log(this.props);
        this.state = {
            isLoginModalVisible: false,
            loginEmail: localStorage.getItem('loginEmail') || "", // Initialize from localStorage
            password: "", // we dont store the password as we want to protact the user information, enhance the security of the website 
            isLoggedIn: localStorage.getItem('isLoggedIn') === 'true', // Check login status from local storage
            user_id: localStorage.getItem('user_id') || "",
            user_firstname: localStorage.getItem('user_firstname') || "",
            avatar_url: localStorage.getItem("avatar_url") || "",
        };
        this.formRef = React.createRef();
    };

    

    // clearLoginData = () => {
    //     // Clear the login information from localStorage
    //     localStorage.removeItem('isLoggedIn');
    //     localStorage.removeItem('loginEmail');
    //     // ... clear any other relevant login data as needed ...
    // }

    // componentDidMount() {
    //     // Add the event listener when the component mounts
    //     window.addEventListener('beforeunload', this.clearLoginData);
    // }

    // componentWillUnmount() {
    //     // Remove the event listener when the component unmounts
    //     window.removeEventListener('beforeunload', this.clearLoginData);
    // }

    // handle login modal 
    showLoginModal = () => {
        this.setState({ isLoginModalVisible: true });
    }

    // handleLoginOk = () => {
    //     // TODO: Handle the login logic here
    //     this.setState({ isLoginModalVisible: false });
    // }

    handleLoginCancel = () => {
        this.setState({ isLoginModalVisible: false });
    }

    // handle navigation between each feature 
    handleClick = e => {
        // console.log('click ', e);
        const key = e.key;
        sessionStorage.setItem('key', key);

        if (e.key === '1') {
            // console.log("switch to home");
            // console.log(this);
            window.location.href = '/';
            // console.log(this);
        }
        if (e.key === '2') {
            // console.log("switch to online resume");
            window.location.href = '/';
        }
        if (e.key === '3') {
            // console.log("switch to portfolio");
            window.location.href = '/';
        }
        if (e.key === '4') {
            // console.log("switch to meet up");
            window.location.href = '/meetup';
        }
        if (e.key === '5') {
            // console.log("switch to blog");;
            window.location.href = '/blog';
        }
        if (e.key === '6') {
            // console.log("switch to newsletter signup");
            window.location.href = '/';
        }
        if (e.key === '7') {
            // console.log("switch to inquiry");
            window.location.href = '/inquiry';
        }
        if (e.key === '8') {
            // console.log("login");
            this.showLoginModal();
            
        }
        if (e.key === '9') {
            // console.log("check profile");
            // console.log(this.props);
            // this.props.props.history.push("/profile", {
            //     loginEmail: this.state.loginEmail,
            //     key: "9",
            // });
            window.location.href = '/profile';
        }

    }

    handleLoginOk = () => {
        this.formRef.current.submit();
    }

    onLogin = () => {
        axios
            .post("/user/login", {
                loginEmail: this.state.loginEmail,
                password: this.state.password,
            })
            .then((response) => {
                // console.log(response);
                if (response.data.success) {
                    // console.log(response.data.user);
                    // console.log("logined in");
                    this.setState({
                        isLoggedIn: true,
                        isLoginModalVisible: false,
                        loginEmail: this.state.loginEmail,
                        user_id: response.data.user.id,
                        user_firstname: response.data.user.givenName,

                    });
                    localStorage.setItem('isLoggedIn', 'true'); // Set login state in local storage
                    localStorage.setItem('loginEmail', this.state.loginEmail); // Store login email
                    localStorage.setItem('user_id', response.data.user.id );
                    localStorage.setItem('user_firstname', response.data.user.givenName);
                    localStorage.setItem('avatar_url', response.data.user.avatar);
                    
                } else {
                    // console.log("failed");
                    this.setState({
                        isLoggedIn: false,
                        isLoginModalVisible: false
                    });
                    
                    alert("You are kindly requested to provide the appropriate login credentials, including your email address and password, to proceed with the authentication process.");
                    localStorage.removeItem('isLoggedIn'); // Remove login state from local storage
                    localStorage.removeItem('loginEmail'); 
                }
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    // new user -> register 
    handleRegisterClick = () =>{
        // console.log("switch to register page");
        window.location.href = '/register';
    }

    // existing user but forget password -> reset password
    handleForgotPasswordClick = () => {
        // console.log("switch to forgot password page");
        window.location.href = '/password';
    }

    render() {
        return (
            <Layout>
                <Header>
                    <Menu onClick={this.handleClick}
                        theme="dark"
                        mode="horizontal"
                        selectedKeys={[sessionStorage.getItem('key')]}
                        defaultSelectedKeys={2}>

                        {this.state.isLoggedIn ? (
                            <Menu.Item key="9">
                                <Link to="/profile">{localStorage.getItem('user_firstname')}</Link>
                            </Menu.Item>
                        ) : (
                            <Menu.Item key="8" icon={<UserOutlined />}>
                                Login
                            </Menu.Item>
                        )}

                        {/* <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item> */}
                        <Menu.Item key="2"><Link to="./">Online Resume</Link></Menu.Item>
                        {/* <Menu.Item key="3"><Link to="/">Portfolio</Link></Menu.Item> */}
                        <Menu.Item key="4"><Link to="/">Let's Meet Up</Link></Menu.Item>
                        {/* <Menu.Item key="5"><Link to="./blog">Blog</Link></Menu.Item>
                        <Menu.Item key="6"><Link to="./newsletter">Newsletter Signup</Link></Menu.Item> */}
                        <Menu.Item key="7"><Link to="/">Submit Inquiry</Link></Menu.Item>


                        {/* Add more navigation links */}
                    </Menu>

                    {/*login modal here*/}
                    <Modal
                        title="Login to Unlock More Features"
                        open={this.state.isLoginModalVisible}
                        onOk={this.handleLoginOk}
                        onCancel={this.handleLoginCancel}
                        footer={[
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button key="ok" type="primary" onClick={this.handleLoginOk} style={{ width: '48%' }}>
                                    Login
                                </Button>
                                <Button key="cancel" onClick={this.handleLoginCancel} style={{ width: '48%' }}>
                                    Cancel
                                </Button>
                            </div>
                        ]}
                    >
                        <p>Login details can also be used for our Job Application Tracker Website</p>
                        <p><a href=''>Offers Tracker</a> (Currently Still in Development)</p>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Form
                            ref={this.formRef}
                            name="normal_login"
                            initialValues={{ remember: true }}
                            onFinish={this.onLogin}
                            style={{ backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '8px'}}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your login email!' }]}
                                onChange={(e) => this.setState({ loginEmail: e.target.value })}
                                
                            >
                                <Input prefix={<UserOutlined />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                                onChange={(e) => this.setState({ password: e.target.value })}
                            >
                                <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
                            </Form.Item>

                            <Form.Item>
                                {/* Registration Button */}
                                <Button
                                    type="link"
                                    onClick={this.handleRegisterClick}
                                    style={{ float: 'right' }}
                                >
                                    Register 
                                </Button>
                                {/* Forgot Password Button */}
                                <Button
                                    type="link"
                                    onClick={this.handleForgotPasswordClick}
                                    style={{ float: 'right' }}
                                >
                                    Forgot password?
                                </Button>
                            </Form.Item>
                            </Form>
                        </div>


                    </Modal>
                </Header>
                <Content style={{ padding: '20px' }}>
                </Content>
            </Layout>
        );
    }

};

export default withRouter(NavigationBar);
