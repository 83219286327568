import React, { Component } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import {MailOutlined, VerticalLeftOutlined} from '@ant-design/icons';
import axios from "../API/axios";  // assuming axios instance is set up this way
import { withRouter } from 'react-router-dom';

const { Title } = Typography;

class RegisterPage extends Component {
    constructor(props){
        // console.log(props);
        super(props);
        this.state = {
            loginEmail: "",
            verify_code: "",
            input_code: "",
            verify_code_status: false,
            registered_email: "",

            // for generate code button 
            isLoading: false,
            countdown: 0,
            btnDisabled: false,
        }
    }
    

    // for cancel button, just go back to the original back 
    handleCancel = () =>{
        this.props.history.goBack();
    }

    // email registration code function
    handleSendVerification = () => {
        this.setState({ isLoading: true, btnDisabled: true });

        axios.post("/user/send-registration-code", { email: this.state.loginEmail })
            .then(response => {
                if (response.data.success) {
                    // console.log(response.data);
                    alert('Verification code sent to your email.');
                    this.setState({
                        verify_code: response.data.code,
                        registered_email: response.data.email,
                        isLoading: false,
                        countdown: 60
                    });
                    this.startCountdown();
                } else {
                    this.setState({ isLoading: false, btnDisabled: false });
                    // console.log(response.data);
                    alert(response.data.message);
                }
            })
            .catch(error => {
                this.setState({ isLoading: false, btnDisabled: false });
                console.log(error);
            });
    }

    // countdown clock for genreate code button (after generating a code, there will be 60s frozen period for next generate code action)
    startCountdown = () => {
        const timer = setInterval(() => {
            this.setState(prevState => ({
                countdown: prevState.countdown - 1
            }));

            if (this.state.countdown <= 0) {
                clearInterval(timer);
                this.setState({ btnDisabled: false });
            }
        }, 1000);
    }


    handleVerify = () =>{
        // console.log(this.state.verify_code)
        // console.log(this.state.input_code)
        // console.log(this.state.loginEmail)
        // console.log(this.state.registered_email)

        if (this.state.verify_code == this.state.input_code && this.state.loginEmail == this.state.registered_email){

            // this.props.history.push("/complete", {loginEmail: this.state.loginEmail});
            // not working with the history.push method, so we change to redirect href and store the data in localstorage
            localStorage.setItem("register_email", this.state.loginEmail);
            window.location.href = '/complete';

        }
        // console.log("verified");
    }

    render() {
        return (
            <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
                <Title level={2}>Register an Account</Title>
                <p>You can also use the same login details for Offers Tracker System</p>
                <p>Browse <a href=''>Offers Tracker</a></p>
                <Form
                    name="normal_register"
                    onFinish={this.handleVerify}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                        onChange={(e) => this.setState({ loginEmail: e.target.value })}
                    >
                        <Input prefix={<MailOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            onClick={this.handleSendVerification}
                            loading={this.state.isLoading}
                            disabled={this.state.btnDisabled}
                        >
                            {this.state.countdown > 0 ? `Resend Code after ${this.state.countdown} s` : "Generate Code"}
                        </Button>

                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: 'Please input your verification code' }]}
                        onChange={(e) => this.setState({ input_code: e.target.value })}
                    >
                        <Input prefix={<VerticalLeftOutlined />} placeholder="Verification Code" />
                        
                    </Form.Item>



                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Next Step
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="secondary" htmlType="Cancel" style={{ width: '100%' }} onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default withRouter(RegisterPage);
