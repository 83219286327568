import React, { Component } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined, HomeOutlined } from '@ant-design/icons';
import axios from "../API/axios";  // assuming axios instance is set up this way
import { withRouter } from 'react-router-dom';

const { Title } = Typography;

class CompleteRegister extends Component {
    constructor(props) {
        // console.log(props);
        super(props);
        this.state = {
            loginEmail: localStorage.getItem("register_email"),
            password: "",
            givenName: "",
            familyName: "",
            phone: "",
            address: "",
            password_confirm: "",
            passwordStrength: "", // Added for password strength feedback
            passwordPass: false
        }
    }

    // Function to check password strength
    checkPasswordStrength = (value) => {
        // Define your password strength criteria
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

        // Check for uppercase letter
        const hasUppercase = uppercaseRegex.test(value);

        // Check for special character
        const hasSpecialChar = specialCharRegex.test(value);

        // Update password strength feedback
        if (hasUppercase && hasSpecialChar && value.length >= 8) {
            this.setState({ passwordStrength: "strong" });
        } else if (hasUppercase || hasSpecialChar || value.length >= 8) {
            this.setState({ passwordStrength: "medium" });
        } else {
            this.setState({ passwordStrength: "weak" });
        }
        if (value.length >= 8) {
            this.setState({ passwordPass: true });
        }
        else {
            this.setState({ passwordPass: false });
        }
    };

    getPasswordStrengthColor = () => {
        switch (this.state.passwordStrength) {
            case 'weak':
                return 'red';
            case 'medium':
                return 'orange';
            case 'strong':
                return 'green';
            default:
                return 'inherit';
        }
    };

    handleSubmit = (values) => {

        // check if the password and password_confirm is same
        if (this.state.password == this.state.password_confirm && this.state.passwordPass) {
            // console.log("password same");
            // console.log("verfied email");
            // console.log(this.state.loginEmail);
            // console.log(this.state.givenName);
            axios.post("/user/create", {
                loginEmail: this.state.loginEmail,
                password: this.state.password,
                givenName: this.state.givenName,
                familyName: this.state.familyName,
                phone: this.state.phone,
                address: this.state.address
            })
                .then((response) => {
                    // console.log(response);
                    if (response.data.success) {
                        alert("successfully resgitered the account");
                        localStorage.setItem('isLoggedIn', 'true'); // Set login state in local storage
                        localStorage.setItem('loginEmail', response.data.user.loginEmail); // Store login email
                        localStorage.setItem('user_id', response.data.user.id);
                        localStorage.setItem('user_firstname', response.data.user.givenName)
                        localStorage.removeItem("register_email");
                        window.location.href = '/'
                    }
                    else {
                        alert("This email has been registered!");
                    }
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        else {
            if (this.state.passwordPass){
                alert("The second entry for password confirmation does not match the initial input. Kindly verify and re-enter your password to ensure accuracy and consistency.")
            }
            else{
                alert("Password must be at least 8 characters.");
            }
        }

    }

    // for cancel button, just go back to the original back 
    handleCancel = () => {
        this.props.history.goBack();
    }


    render() {
        return (
            <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
                <Title level={2}>Register an Account for {localStorage.getItem("register_email")}</Title>
                <p>You can also use the same login details for Offers Tracker System</p>
                <p>Browse <a href=''>Offers Tracker</a></p>
                <Form
                    name="normal_register"
                    onFinish={this.handleSubmit}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    // Check password strength
                                    if (!value || value.length < 8) {
                                        return Promise.reject(
                                            new Error('Password must be at least 8 characters.')
                                        );
                                    }
                                    if (getFieldValue('password_confirm') && value !== getFieldValue('password_confirm')) {
                                        return Promise.reject(
                                            new Error('Passwords do not match.')
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => {
                            this.setState({ password: e.target.value });
                            this.checkPasswordStrength(e.target.value);
                        }}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    
                    <Form.Item
                        name="password_confirm"
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    // Check password confirmation
                                    if (getFieldValue('password') && value !== getFieldValue('password')) {
                                        return Promise.reject(
                                            new Error('Passwords do not match.')
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => this.setState({ password_confirm: e.target.value })}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Confirm Password"
                        />
                    </Form.Item>

                    {/* Password strength feedback */}
                    {this.state.password && (
                        <div style={{ marginTop: '16px', textAlign: 'center' }}>
                            <strong>Password Strength:</strong>
                            <span
                                style={{
                                    marginLeft: '8px',
                                    color: this.getPasswordStrengthColor(),
                                }}
                            >
                                {this.state.passwordStrength}
                            </span>
                        </div>

                    )}

                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                        onChange={(e) => this.setState({ givenName: e.target.value })}
                    >
                        <Input prefix={<UserOutlined />} placeholder="First Name" />
                    </Form.Item>

                    <Form.Item
                        name="family name"
                        rules={[{ required: true, message: 'Please input your family name!' }]}
                        onChange={(e) => this.setState({ familyName: e.target.value })}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Last Name" />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number! ' }]}
                        onChange={(e) => this.setState({ phone: e.target.value })}
                    >
                        <Input prefix={<PhoneOutlined />} placeholder="Phone Number" />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        rules={[{ required: true, message: 'Please input your address! ' }]}
                        onChange={(e) => this.setState({ address: e.target.value })}
                    >
                        <Input prefix={<HomeOutlined />} placeholder="Address" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Register
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="secondary" htmlType="Cancel" style={{ width: '100%' }} onClick={this.handleCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default withRouter(CompleteRegister);
