import React, { useEffect, useState } from 'react';
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer"
import { Layout, Button, Card, List, Typography, Spin, Divider, Form } from 'antd';
import axios from "../API/axios";
import ChangePassword from "../Components/Changepassword_popup_modal";
import "./Profile.css";
import AvatarSelector from '../Components/Avatar';
import InfiniteScroll from 'react-infinite-scroll-component';
import EditEventModal from '../Components/Edit_event-modal';
import { LogoutOutlined, EditOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

function ProfilePage(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [allEvents, setAllEvents] = useState([]);
    const [displayedEvents, setDisplayedEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastname, setLastName] = useState();
    const [phone, setPhone] = useState();

    useEffect(() => {
        axios.get("/meetup/user-create/?user=" + localStorage.getItem("user_id"))
            .then((response) => {
                if (response.data.success) {
                    setAllEvents(response.data.events);
                    setDisplayedEvents(response.data.events.slice(0, 10)); // Display first 10 events initially
                }
            });
        axios.get("/user/detail/?id=" + localStorage.getItem("user_id"))
            .then((response) => {
                // console.log(response);
                if(response.data.success){
                    setLastName(response.data.user[0].familyName);
                    setPhone(response.data.user[0].phone);
                }
            })
    }, []);

    const fetchMoreData = () => {
        if (displayedEvents.length >= allEvents.length) {
            setHasMore(false);
            return;
        }

        // Fetch next 10 events
        const moreEvents = allEvents.slice(displayedEvents.length, displayedEvents.length + 10);
        setDisplayedEvents(displayedEvents.concat(moreEvents));
    };

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_firstname');
        setIsModalVisible(false);
        window.location.href = '/';
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    // handle edit event feature below
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editingEvent, setEditingEvent] = useState(null);

    const showEditModal = (event) => {
        setEditingEvent(event);
        setIsEditModalVisible(true);
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
    };

    return (
        <Layout>
            <Header>
                <Navigation props={props}></Navigation>
            </Header>
            <Content className="profile" style={{ width: '100%', padding: '20px' }}>


                <div className="profile-container">
                    <div className="profile-header">
                        <Title level={2}>Welcome, {localStorage.getItem('user_firstname')}</Title>
                        <Paragraph strong></Paragraph>
                        <Button className="small-button" onClick={handleLogout} type="dashed" shape="round" style={{ width: "50%" }}><LogoutOutlined />Log out</Button>
                        <p></p>
                        <Button className="small-button" onClick={showModal} type="dashed" shape="round" style={{ width: "50%" }} ><EditOutlined />Change Password</Button>
                        <ChangePassword visible={isModalVisible} onClose={handleCloseModal}></ChangePassword>
                    </div>

                    <div className="profile-intro">
                        <Paragraph strong>Access Other Platforms </Paragraph>
                        <a href='/'>Offers Tracker Platform (Currently still in development)</a>
                    </div>
                    
                    <div className="profile-photo-container">
                        {/* Content for the profile photo container */}
                        <AvatarSelector></AvatarSelector>
                    </div>
                </div>


                <div style={{ padding: '20px' }}>
                    <Card>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Title level={2}>{localStorage.getItem('user_firstname')}'s Profile</Title>
                            {/* <Button style={{ width: '10%' }} className="small-button" onClick={handleLogout} type="dashed" shape="round">Edit Profile</Button> */}
                        </div>
                        <p>Name: {localStorage.getItem('user_firstname') + " " + lastname}</p>
                        <p>Email: {localStorage.getItem('loginEmail')}</p>
                        <p>Phone Number: {phone}</p>
                    </Card>


                    <Card style={{ marginTop: '20px' }}>
                        <Title level={2}>Meetup Events Created</Title>
                        <div style={{ height: '300px', overflow: 'auto' }}>
                            <InfiniteScroll
                                dataLength={displayedEvents.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<Spin />}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <Divider plain>It is all, nothing more 🤐
                                            <a href='/meetup' target="_blank" rel="noopener noreferrer"> Create More Meetups Here </a></Divider>
                                    </p>
                                }
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={displayedEvents}
                                    renderItem={event => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={event.title}
                                                description={event.event}
                                            />
                                            <div><Button onClick={() => showEditModal(event)}>Edit Event</Button></div>

                                            <EditEventModal
                                                event={editingEvent}
                                                visible={isEditModalVisible}
                                                onCancel={handleEditCancel}
                                            />

                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Card>

                </div>
            </Content>
            <Footer></Footer>
        </Layout>
    );
}

export default ProfilePage;



