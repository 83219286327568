import React, { Component } from 'react';
import { Avatar, Modal, List } from 'antd';
import axios from "../API/axios";
import {EditOutlined } from '@ant-design/icons';

class AvatarSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedAvatar: localStorage.getItem("avatar_url"),
        };
    }

    // Function to show the modal
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    // Function to hide the modal
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    // Function to handle avatar selection
    selectAvatar = (avatar) => {
        this.setState({
            selectedAvatar: avatar,
            visible: false,
        });
        console.log(avatar);
        console.log(localStorage.getItem("loginEmail"));
    
        axios.post("/user/change-avatar", {
            loginEmail: localStorage.getItem("loginEmail"),
            avatar: avatar,
        }).then((response) => {
            console.log(response)
            if (response.data.message === "Changed Avatar"){
                localStorage.setItem("avatar_url", avatar);
                window.history.go(0);
            }
            else{
                alert("Error in changing avatar");
            }
        })
        // POST change to backend for changing avatar 

    };

    render() {
        // Generate a list of avatars using Dicebear API
        const avatars = Array.from({ length: 60 }, (_, index) => ({
            id: index + 30,
            src: `https://api.dicebear.com/7.x/pixel-art/svg?seed=${index}`,
        }));

        return (
            <div>
                <Avatar
                    shape="circle"
                    size={64}
                    src={this.state.selectedAvatar}
                    onClick={this.showModal}
                    style={{ cursor: 'pointer' }}
                />
                <p>
                </p>
                <EditOutlined />
                <Modal
                    title="Choose an Avatar"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    width={800}  // Set the width as per your requirement
                    footer={null}
                >
                    <List
                        grid={{ gutter: 16, xs: 4, sm: 6, md: 8, lg: 12 }}
                        dataSource={avatars}
                        renderItem={(item) => (
                            <List.Item>
                                <Avatar
                                    shape="circle"
                                    size={64}
                                    src={item.src}
                                    onClick={() => this.selectAvatar(item.src)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </List.Item>
                        )}
                    />
                </Modal>
            </div>
        );
    }
}

export default AvatarSelector;
