import './App.css';
import React from 'react';
import Navigation from './Components/Navigation'
import Footer from "./Components/Footer"
import { Layout, Menu } from 'antd';
const { Header, Content } = Layout;

const App = (props) => {


  
    return (
      <Layout>
      <Header>
          <Navigation props={props}></Navigation>
      </Header>
      <Content style={{ padding: '20px' }}>
      </Content>
      <Footer></Footer>
      </Layout>
    );
  }

export default App;
