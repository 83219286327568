import React from 'react';
import { Card, List } from 'antd';

const data = [
  {
    title: 'Programming Languages',
    sub: ["Python", "Java", "JavaScript", "C/C#", "Haskell", "MatLab/R"],
  },
  {
    title: 'Frameworks & Libraries',
    sub: ["React", "Node.js", "Express", "Scikit-Learn", "Pandas", "Pytorch"],
  },
  {
    title: 'AI',
    sub: ["Machine Learning", "Deep Learning", "Computer Vision"],
  },
  {
    title: 'Databases',
    sub: ["MySQL", "MongoDB"],
  },
  {
    title: 'Web Technologies',
    sub: ["HTML", "CSS"],
  },
  {
    title: 'Version Control',
    sub: ["Git", "GitHub", "GitLab"],
  },
  {
    title: 'Computer Software',
    sub: ["Latex", "Postman", "Insomnia", "Microsoft Office"],
  },
  {
    title: 'Soft Skills',
    sub: ["Public Speaking", "Multitasking", "Teamwork", "Problem Solving", "Adaptability", "Time Management"],
  }
];

const Skills = () => (
  <List
    grid={{
      gutter: 16,
      xs: 1,   // 1 card for extra small screens
      sm: 2,   // 2 cards for small screens
      md: 4,   // 4 cards for medium screens
      lg: 4,   // 4 cards for large screens
      xl: 4,   // 4 cards for extra large screens
      xxl: 4,  // 4 cards for extra-extra large screens
    }}
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <Card title={item.title}>
          <ul>
            {item.sub.map(skill => <li key={skill}>{skill}</li>)}
          </ul>
        </Card>
      </List.Item>
    )}
  />
);

export default Skills;
