import React from 'react';
import { Layout, Typography, Card, Divider, List, Button } from 'antd';
import { MailOutlined, LinkedinOutlined, GithubOutlined, DownloadOutlined } from '@ant-design/icons';
import Navigation from '../Components/Navigation'
import Footer from "../Components/Footer"
import Skills from "../Components/Skills"
import "./OnlineResume.css"

const { Header, Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const exp_1 = [
  'Supervising student teams during their capstone IT Project (COMP30022), organising regular meetings and providing guidance. Test the related code and mark it accordingly.',
  'Creating weekly workshops focused on agile principles, project delivery, and other important software delivery concepts.',
];

const exp_2 = [
  'Teaching courses focused on computing topics, including Foundations of Computing (Python), and Data Processing (Python).',
  'Effectively presented the curriculum to students, actively engaging them in the learning process.',
];

const exp_3 = [
  'Collaborated with clients to determine project requirements and create a comprehensive project plan.',
  'Designed and developed a mobile-terminal electronic menu and business-end control module using HTML, CSS, and the React library.',
];

const research_data = [
  'Built the virtual education system with a VR component using the Unity platform and programming languages including C#, PHP, and MySQL for database.',
  'Conducted extensive research into various recommendation system filters and integrated the most effective options into a hybrid recommendation system filter, optimising performance and enhancing the user experience.',
  'Prioritised user-friendliness in the UI design process, ensuring that the virtual education system is intuitive and easy to use.',
  'Conducted full stack development for the virtual education system and tested all related functionalities to ensure a smooth and error-free system.',
];

const project_data1 = [
  'Designed and developed a lightweight CRM system website using MERN (MongoDB, Express, React, Node) Stack with a user-friendly front-end and error-free back-end.',
  'Developed a wide range of functionalities for the CRM system, including `Live Chat`, `Public and Private Calendar` sections, `Customer Information and Logging`, and `Audit Trails` to ensure accurate tracking of user actions.',
  'Responsible for development in back-end functionalities and established seamless interaction between front-end and back-end components.',
  'Managed task assignments and monitored the progress of team members to ensure timely completion of projects.',
  'Ranked in the Top 10 among all development projects completed at the University of Melbourne.',
];

const project_data2 = [
  'Designed and developed a modern customer-end interactive sales website and business-end control website using MERN Stack.',
  'Developed a range of functionalities for the website, including `Live Update` capability for business-end order processing, `Online Order` functionality for customers, and an `Automatic Map` feature to facilitate location-based services.',
  'Responsible for development in back-end functionalities and conducted testing (Unit Test & Integration Test) to ensure a reliable and error-free website.',
  'Maintained regular communication with the project supervisor to provide updates on development progress and discuss any concerns or issues.',
];

const extra_data1 = [
  'Committee Member as photographer.',
  'Responsible for activity planning, propaganda and stage effect presentation.',
];

const extra_data2 = [
  'Explored the optimal feasible implementation plans with students and staff from Universitas 21.',
  'Awarded the honour of Global Citizenship Leader certified by Common Purpose.',
];


const OnlineResume = (props) => {

  const loginEmail = localStorage.getItem('loginEmail');
  // console.log('User Email:', loginEmail);

  return (
    <Layout>
      <Header>
        <Navigation props={props}></Navigation>
      </Header>
      <Content className="online-resume" style={{ width: '100%', padding: '20px' }}>
        <div className="resume-container">
          <div className="resume-header">
            <Title level={2}>Yitong (Daisy) Pei</Title>
            <Paragraph strong>Software Engineer | Academic Supervisor | Photographer</Paragraph>
            <p>
              <a href="mailto:yitongp22@gmail.com"><MailOutlined /> Email </a>
              <a href="https://www.linkedin.com/in/daisy-pei-b67763211/" target="_blank" rel="noopener noreferrer"><LinkedinOutlined /> LinkedIn </a>
              <a href="https://github.com/1tongp" target="_blank" rel="noopener noreferrer"><GithubOutlined /> GitHub </a>
            </p>
            <a href={"/Resume_Yitong_Pei.pdf"} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', padding: '4px 8px', fontSize: '12px' }}>
              <Button type="dashed" shape="round" icon={<DownloadOutlined />}>Download Resume</Button>
            </a>

          </div>

          <div className="short-intro">
            <Paragraph strong>Tutor @ Unimelb</Paragraph>
            <Paragraph strong>22' MSAI @ NTU, Singapore</Paragraph>
            <Paragraph strong>20' Bsc in CS @ Unimelb, Australia</Paragraph>
          </div>

          <div className="photo-container" >
            <img src="/Daisy_Pei.png" alt="Yitong Pei" style={{ maxWidth: '200px', maxHeight: '200px', width: 'auto', height: 'auto', objectFit: 'contain' }} />
          </div>

        </div>






        <Divider />

        <section className="About">
          <Title level={3}>Professional Profile</Title>
          <Paragraph>
            As a passionate and motivated postgraduate student in Artificial Intelligence,
            I am seeking to leverage my practical learnings and experience and grow my career as a Graduate Software Engineer.
            I have completed several web development projects with MERN stack, and research projects based on VR and recommendation systems.
            I am a fast and efficient learner, with a proven ability to manage multiple priorities in deadline-driven environments.
          </Paragraph>
        </section>

        <Divider />

        <section className="Education">
          <Title level={3}>Education</Title>
          <Card
            title="Nanyang Technological University, Singapore"
            extra={"Aug 2022 – Anticipated Dec 2023"}
            className="responsive-card"
          >
            <Text>Master of Science in Artificial Intelligence</Text>
            <br />
            <Text>GPA: 4.44/5</Text>
            <br />
            <Text>Courses include: Deep Learning, Machine Learning, Computer Vision, Multi-Agent System, Time Series Analysis and etc. </Text>
          </Card>

          <Card
            title="Nanyang Technological University, Singapore"
            extra={"Jan 2022 – May 2022"}
            className="responsive-card"
          >
            <Text>Bachelor of Computer Engineering</Text>
            <br />
            <Text>Semester Exchange Program at the School of Computer Science and Engineering</Text>
            <br />
            <Text>Courses include: Operating Systems, Data Structures & Algorithms, Algorithms for the Real World, Introduction to Psychology. </Text>
          </Card>

          <Card
            title="University of Melbourne, Australia"
            extra={"Mar 2020 – Jul 2022"}
            className="responsive-card"
          >
            <Text>Bachelor of Science in Computing and Software Systems </Text>
            <br />
            <Text>WAM: 83.625/100 {' '}
              <a href="https://www.myequals.net/r/sharelink/5d257d95-936b-48c4-bcfd-a76a9d1c7340/36e58e30-8ea9-44b6-9d97-9a2358dab92d" target="_blank" rel="noopener noreferrer">
                Review Transcript
              </a>
            </Text>
            <br />
            <Text>Courses include: Algorithms, Object-Oriented Software Development, MERN Stack Web Development, Probability, Statistics, Operating Systems, Design Pattern Application and etc.</Text>
          </Card>
        </section>


        <Divider />

        <section className="Experience">
          <Title level={3}>Experience</Title>

          <Card
            title="Academic Supervisor"
            extra={"Jul 2023 - Present"}
            className="responsive-card"
          >
            <Text type="secondary">Faculty of Engineering and IT, University of Melbourne</Text>
            <br />
            <Text type="secondary">Melbourne, Australia</Text>
            <List
              dataSource={exp_1}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

          <Card
            title="Academic Tutor"
            extra={"Aug 2020 – Jun 2023"}
            className="responsive-card"
          >
            <Text type="secondary">Navigation International Pty Ltd</Text>
            <br />
            <Text type="secondary">Melbourne, Australia</Text>
            <List
              dataSource={exp_2}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

          <Card
            title="Front-end Development Intern"
            extra={"Dec 2020 – Feb 2021"}
            className="responsive-card"
          >
            <Text type="secondary">Digital Artery Pty Ltd</Text>
            <br />
            <Text type="secondary">Melbourne, Australia</Text>
            <List
              dataSource={exp_3}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

        </section>

        <Divider />
        <section className="Portfolio">
          <Title level={3}>Portfolio</Title>

          <Card
            title="Research Project - Metaverse for Virtual Education"
            extra={<a href={"/Yitong(Daisy)Pei_Metaverse_for_Virtual_Education.pdf"} target="_blank" rel="noopener noreferrer">
              See Paper
            </a>
            }
            className="responsive-card"
          >
            <Text type="secondary">Aug 2022 - Apr 2023</Text>
            <br />
            <Text type="secondary">Associated with Nanyang Technological University</Text>
            <List
              dataSource={research_data}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

          <Card
            title="Development Project 1 - CRM System"
            extra={<a href="https://github.com/1tongp/CRM-RoomOfRequirements" target="_blank" rel="noopener noreferrer">See Code</a>}
            className="responsive-card"
          >
            <Text type="secondary">Aug 2021 - Nov 2021</Text>
            <br />
            <Text type="secondary">Associated with University of Melbourne</Text>
            <List
              dataSource={project_data1}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

          <Card
            title="Development Project 2 - Cafe Shop Platform"
            extra={<a href="https://github.com/1tongp/SnacksInVan-KeepItSimple/tree/main" target="_blank" rel="noopener noreferrer">See Code</a>}
            className="responsive-card"
          >
            <Text type="secondary">Mar 2021 - Jul 2021</Text>
            <br />
            <Text type="secondary">Associated with University of Melbourne</Text>
            <List
              dataSource={project_data2}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>


        </section>

        <Divider />

        <section className="Skills">
          <Title level={3}>Skills</Title>
          <Skills></Skills>
        </section>

        <Divider />

        <section className="Awards">
          <Title level={3}>Awards and Certificates</Title>
          <Card
            title="Melbourne Plus People Leadership Certificate"
            extra={"Aug 2023"}
            className="responsive-card"
          >
            <Text type='secondary'>Associated with Univeristy of Melbourne</Text>
            <Paragraph>
              Participated in the U21 Common Purpose Global Citizens Programme, where worked with diverse teams and demonstrated leadership throughout the program
            </Paragraph>
          </Card>

          <Card
            title="Global Mobility Awards Scholarship"
            extra={"Mar 2022"}
            className="responsive-card"
          >
            <Text type='secondary'>Associated with Univeristy of Melbourne</Text>
            <Paragraph>
              Academic Scholarship
            </Paragraph>
          </Card>

          <Card
            title="Melbourne International Scholarship"
            extra={"Nov 2021"}
            className="responsive-card"
          >
            <Text type='secondary'>Associated with Univeristy of Melbourne</Text>
            <Paragraph>
              Academic Scholarship
            </Paragraph>
          </Card>

        </section>

        <Divider />

        <section className="Extra-Curriculum Activities">
          <Title level={3}>Extra-Curriculum Activities</Title>
          <Card
            title="Melbourne University Chinese Theatre Group"
            extra={"Mar 2020 – Present"}
            className="responsive-card"
          >
            <Text type="secondary">University of Melbourne</Text>
            <List
              dataSource={extra_data1}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

          <Card
            title="Common Purpose and Universitas 21 Sustainable Development Program"
            extra={"April 2021 – May 2021"}
            className="responsive-card"
          >
            <Text type="secondary">Common Purpose and Universitas 21</Text>
            <List
              dataSource={extra_data2}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <span style={{ marginRight: '8px' }}>•</span> {item}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </Card>

        </section>

        <Divider />

        <section className="Contact">
          <Title level={3}>Contact Me</Title>
          <Paragraph>
            If you'd like to get in touch or collaborate on a project,
            feel free to reach out to me.
          </Paragraph>

          <a href="mailto:yitongp22@gmail.com"><MailOutlined /> Email</a>
          <a href="https://www.linkedin.com/in/daisy-pei-b67763211/" target="_blank" rel="noopener noreferrer"><LinkedinOutlined /> LinkedIn</a>
          <a href="https://github.com/1tongp" target="_blank" rel="noopener noreferrer"><GithubOutlined /> GitHub</a>
        </section>
      </Content>
      <Footer></Footer>
    </Layout>
  );
};

export default OnlineResume;
