import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "./router.js";
import App from "./App.js"
import OnlineResume from './Online_resume/OnlineResume_old.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);


// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
// import Routes from './router.js'; // Import your Routes component

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <Router> {/* Wrap your app with Router */}
//       <Routes /> {/* Use the Routes component */}
//     </Router>
//   </React.StrictMode>
// );
