import React from 'react';
import { Layout } from 'antd';
import { MailOutlined, LinkedinOutlined, GithubOutlined } from '@ant-design/icons';
import './Footer.css'; // You can create a CSS file for your footer styles

const { Footer: AntdFooter } = Layout;

const Footer = () => {
  return (
    <AntdFooter className="site-footer">
      <div className="footer-content">
        <div className="footer-info">
          <b>&copy; {new Date().getFullYear()} Yitong (Daisy) Pei. All rights reserved.</b> {" "}
          <a href="mailto:yitongp22@gmail.com"><MailOutlined /> Email</a> {" "}
        <a href="https://www.linkedin.com/in/daisy-pei-b67763211/" target="_blank" rel="noopener noreferrer"><LinkedinOutlined /> LinkedIn</a> {" "}
        <a href="https://github.com/1tongp" target="_blank" rel="noopener noreferrer"><GithubOutlined /> GitHub</a>
          <br/>
          If you'd like to get in touch or collaborate on a project,
          feel free to reach out to me or <a href="/inquiry" target="_blank" rel="noopener noreferrer">submit an inquiry</a> through this website. 

          <br/>
          This website is still under development, and some features may not be working yet.
            Your feedback is much appreciated, so please feel free to contact me if you come across any problems.
        </div>
      </div>
    </AntdFooter>
  );
};

export default Footer;
