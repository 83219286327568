import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Radio, DatePicker } from 'antd';
import './EditEventModal.css';
import moment from 'moment';
import axios from "../API/axios";

const { RangePicker } = DatePicker;

const EditEventModal = ({ event, visible, onCancel }) => {
    const [form] = Form.useForm();
    const [overlayVisible, setOverlayVisible] = useState(false);

    useEffect(() => {
        if (event && visible) {
            const startMoment = event.start ? moment(event.start) : null;
            const endMoment = event.end ? moment(event.end) : null;

            form.setFieldsValue({
                ...event,
                start: startMoment,
                end: endMoment,
                dateRange: [startMoment, endMoment]
            });

            setOverlayVisible(true);
        } else if (!visible) {
            setOverlayVisible(false);
        }
    }, [event, form, visible]);


    const handleCancel = () => {
        setDateName("dateRange");
        setIsRangePickerDisabled(true);
        setOverlayVisible(false);
        onCancel();
        
    };

    // handle time range 

    const [isRangePickerDisabled, setIsRangePickerDisabled] = useState(true);
    const [dateName, setDateName] = useState('dateRange');
    const [dateRange, setDateRange] = useState([moment().startOf('day'), moment().endOf('day')]);
    const [updateEvent, setUpdateEvent] = useState({ email: "", event: "", title: "", visibility: "", type: "", contact_email: "" })
    const enableRangePicker = () => {
        setDateName("range-time-picker");
        setIsRangePickerDisabled(false);
        setDateRange([moment().startOf('day'), moment().endOf('day')]); // Reset to default range
    };

    const onOk = () => {
        // console.log(dateRange[0].$d);
        // console.log(dateRange[1].$d);
        // console.log('OK');
        axios.post("/meetup/update-event",
            {
                id: event.id,
                user: localStorage.getItem("user_id"),
                event: updateEvent.event ? updateEvent.event : event.event,
                title: updateEvent.title ? updateEvent.title : event.title,
                startTime: dateRange[0].$d ? dateRange[0].$d : event.start,
                endTime: dateRange[1].$d ? dateRange[1].$d : event.end,
                type: updateEvent.type ? updateEvent.type : event.type,
                visibility: updateEvent.visibility ? updateEvent.visibility : event.visibility,
                contact_email: updateEvent.email ? updateEvent.email : event.email,

            }).then((response) => {
                // console.log(response)
                if (response.data.message == "Meetup updated successfully") {
                    // console.log(response);
                    alert("Meetup updated successfully");
                    window.location.reload(false);
                }
                else {
                    if (response.data.message == "The event clashes with existing activities") {
                        // console.log("clash");
                        alert("The event clashes with existing activities, please select another time!")
                    }
                    else {
                        // console.log(response);
                        alert("Error! Please create again!");
                    }
                }
            })
        };

    
    // handle save button 
    const showAlertSave = () => {
        Modal.confirm({
            title: 'Do you want to change this event?',
            content: updateEvent.title ? updateEvent.title : event.title,
            okText: 'Yes',
            cancelText: 'No',
            footer: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button key="ok" type="primary" onClick={onOk} style={{ width: '48%' }}>
                        Save
                    </Button>
                    <Button key="cancel" onClick={() => { Modal.destroyAll(); handleCancel(); }} style={{ width: '48%' }}>
                        Cancel
                    </Button>
                </div>
            ),
            onCancel() {
                // console.log('Cancel');
                Modal.destroyAll(); // Close the modal when cancel is clicked
            },
        });
    };


    const handleEditOk = () => {
        showAlertSave();
    }

    const onDelete = () => {
        // console.log('OK');
        axios.post("/meetup/delete", {
            id: event.id,
        }).then((response) => {
            // console.log(response);
            if (response.data.success) {
                alert("Successfully deleted this event!");
                window.location.reload(false);
            }
            else {
                alert("Error in deleting event, please try again later.");
            }
        })
    }

    const showAlertDelete = () => {
        // console.log(event.id);
        Modal.confirm({
            title: 'Do you want to delete this event?',
            content: updateEvent.title ? updateEvent.title : event.title,
            okText: 'Yes',
            cancelText: 'No',
            footer: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button key="ok" type="primary" onClick={onDelete} style={{ width: '48%' }}>
                        Delete
                    </Button>
                    <Button key="cancel" onClick={() => { Modal.destroyAll(); handleCancel(); }} style={{ width: '48%' }}>
                        Cancel
                    </Button>
                </div>
            ),
            onCancel() {
                // console.log('Cancel');
            },
        })
    };


    const handleDeleteEvent = () => {
        showAlertDelete();
    }


    return (
        <>
            {overlayVisible && <div className="modal-overlay"></div>}
            <Modal
                className="edit-event-modal"
                title="Edit Event"
                open={visible}
                onCancel={handleCancel}
                footer={[
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button key="ok" type="primary" onClick={handleEditOk} style={{ width: '48%' }}>
                            Save
                        </Button>
                        <Button key="cancel" onClick={handleCancel} style={{ width: '48%' }}>
                            Cancel
                        </Button>
                    </div>
                ]}
                mask={false}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Form
                    form={form}
                    onFinish={handleEditOk}
                    style={{ backgroundColor: 'white', width: '100%', padding: '20px', borderRadius: '8px' }}
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        onChange={(e) => setUpdateEvent({ ...updateEvent, title: e.target.value })}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="event"
                        label="Event Details"
                        onChange={(e) => setUpdateEvent({ ...updateEvent, event: e.target.value })}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="Event Time">
                        <Form.Item
                            name={dateName}
                            noStyle
                            rules={[{ required: true, message: 'Please select time!' }]}
                        >
                            <RangePicker
                                value={dateRange}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={(values) => setDateRange(values)}
                                disabled={isRangePickerDisabled}
                            />
                        </Form.Item>
                        <Button
                            onClick={enableRangePicker}
                            disabled={!isRangePickerDisabled}
                        >
                            Change Time
                        </Button>
                    </Form.Item>




                    <Form.Item
                        name="contact_email"
                        label="Contact Email"
                        onChange={(e) => setUpdateEvent({ ...updateEvent, email: e.target.value })}
                        
                    >
                        <Input />
                        
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label="Event Type"
                        onChange={(e) => setUpdateEvent({ ...updateEvent, type: e.target.value })}
                    >
                        <Radio.Group>
                            <Radio value="Online Meeting">Online Meeting</Radio>
                            <Radio value="In-person Meeting">In-person Meeting</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="visibility"
                        label="Event Visibility Status"
                        tooltip="Select 'Yes' if you want to make this event public, allowing anyone who visits this website to view it."
                        onChange={(e) => setUpdateEvent({ ...updateEvent, visibility: e.target.value })}
                    >
                        <Radio.Group>
                            <Radio value="public">Public</Radio>
                            <Radio value="private">Private</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Button onClick={handleDeleteEvent} style={{ marginLeft: 10 }}>Delete</Button>
                </Form>
                </div>
            </Modal>
        </>
    );
};

export default EditEventModal;

