import React , { useState } from 'react';
import "./OnlineResume.css";
import { Layout} from 'antd';
import Navigation from '../Components/Navigation'
import Footer from "../Components/Footer"
const { Header, Content } = Layout;

const OnlineResume = (props) =>{
    const key = sessionStorage.getItem('key');
    console.log(key) // to switch between nav bar, and highlight the nav bar for the current page 

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    return (
        <Layout>
        <Header>
            <Navigation></Navigation>
        </Header>
        <Content style={{ padding: '20px' }}>
        <div className={`App ${isSidebarOpen ? 'open-sidebar' : ''}`}>
        {/* Sidebar (Navigation Bar) */}
  
  
        <main className="online-resume">
        <header className="App-header">
          <h1>Yitong (Daisy) Pei</h1>
          <p>Software Engineer | Academic Tutor | Photographer</p>
        </header>
        <main>
          <section className="About">
            <h2>About Me</h2>
            <p>
            As a passionate and motivated postgraduate student in Artificial Intelligence, 
            I am seeking to leverage my practical learnings and experience and grow my career as a Graduate Software Engineer. 
            I have completed several web development projects with MERN stack, and research projects based on VR and recommendation systems. 
            I am a fast and efficient learner, with a proven ability to manage multiple priorities in deadline-driven environments.
            </p>
  
            <br/>
  
            <p>
              I am currently in the last year of my Master's degree in Artifical Intelligence at Nanyang Technological University, Singapore.
              I have completed my bachelor's study at University of Melbourne, Australia with a Computer Science degree. 
              As for now, I am working as an Academic Supervior at the Univeristy of Melbourne. 
              I supervise student teams for their capstone IT project and provide guidience for their software development project. 
            </p>
            
          </section>
  
          <section className="Portfolio">
            <h2>Portfolio</h2>
            {/* Add portfolio items here */}
            <div className="Portfolio-item">
              <h3>Project 1 - CRM System - <a
              href="https://github.com/1tongp/CRM-RoomOfRequirements"
              target="_blank"
              rel="noopener noreferrer"
            >
              See Code
            </a>
            </h3>
              <p className='des_p'>Aug 2021 - Nov 2021</p>
              <p className='des_p'>Associated with University of Melbourne</p>
              <p>Our team developed a CRM system for the HSBC insurance department. The main goal of the system is to achieve easy and better connections with their customers. 
                Staff of the HSBC insurance department can get along with their customers to service and offer the right products to them via the designed CRM system.</p>
            </div>
  
            <div className="Portfolio-item">
            <h3>Project 2 - Cafe Shop Platform - <a
              href="https://github.com/1tongp/SnacksInVan-KeepItSimple/tree/main"
              target="_blank"
              rel="noopener noreferrer"
            >
              See Code
            </a>
            </h3>
              <p className='des_p'>Mar 2021 - Jul 2021</p>
              <p className='des_p'>Associated with University of Melbourne</p>
              <p>Designed and developed a modern customer-end interactive sales website and business-end control website. 
                Customers can order food and track the status in the Cafe Shop Platform, and the staff of the Cafe Shop can manage the orders based on priority. </p>
            </div>
          </section>
  
          <section className="Contact">
            <h2>Contact Me</h2>
            <p>
              If you'd like to get in touch or collaborate on a project,
              feel free to reach out to me.
            </p>
            <br/>
            <a href="mailto:yitongp22@gmail.com">Email</a>
            <a
              href="https://www.linkedin.com/in/daisy-pei-b67763211/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
  
            <a
              href="https://github.com/1tongp"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          </section>
        </main>
  
        </main>
  
        
      </div>
        </Content>
        <Footer></Footer>
    </Layout>
    );
  }

export default OnlineResume;