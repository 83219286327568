import '../App.css';
import './Meetup.css';
import React, { useState } from 'react';
import Navigation from '../Components/Navigation';
import Footer from '../Components/Footer';
import { Layout } from 'antd';
import Calendar from '../Components/Calendar_new';
import Event from '../Components/Event_timeline';

const { Header, Content } = Layout;

const Meetup = (props) => {
  return (
    <Layout>
      <Header>
        <Navigation props={props} />
      </Header>

      <Content style={{ padding: '20px', display: 'flex' }}>
        <div style={{ flex: '10%', padding: '10px' }}>
          <Event />
        </div>

        <div style={{ flex: '80%', padding: '10px', width: '100%', maxWidth: '100%' }}>
          <Calendar />
        </div>
      </Content>

      <Footer />
    </Layout>
  );
};

export default Meetup;

